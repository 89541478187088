require('../datatable.js')

$(document).on("turbolinks:load", function() {
  $('#products__search-wrap').text("");
  $('#products__pagination-wrap').text("");
  $('.products-container').datatable({
    search: true,
    searchSelector: '#products__search-wrap',
    namePlural: $('.products-container').attr("plural"),
    nameSingular: $('.products-container').attr("singular"),
    searchPlaceholder: "Search",
    pagingSelector: '#products__pagination-wrap',
    elementSelector: 'tr.product',
    rootnode: '.products-wrap',
    isTable: false,
    elementsPerPage: 100
  });
});
