$.fn.datatable = function(options){
  var datatableData = [];
  var filteredData = [];
  var currentFilter = "";
  var currentPage = 1;
  var searchMessage = "";
  var rootnode = null;

  //Defaults
  options = $.extend({
    elementsPerPage: 10,
    searchPlaceholder: "",
    paging: true,
    pagingSelector: null,
    search: true,
    searchSelector: null,
    rootnode: null,
    isTable: null,
    elementSelector: null,
    isInFilter: (query, object) => {
      return isInFilter(query,object);
    },
    afterUpdate: () => {
      return;
    },
  }, options);

  rootnode = $(options.rootnode);
  if(rootnode.length === 0){
    showError('warn', 'Could not find rootnode, exiting...');
    return;
  }


  const SearchForm = () => `<form action="" id="">
  <div class="input-group mb-1">
  <input type="text" class="search-input form-control" placeholder="` + options.searchPlaceholder + `">
  <div class="input-group-append">
  <button class="datatable-search-button btn btn-outline-secondary">Search</button>
  </div>
  </div>
  <div class="d-flex">
  <p class="search-notification d-inline px-1"></p> <a href="
  #" class="search-reset">Reset.</a>
  </div>
  </form>`;

  const Paging = () => `<div class="datatable-pagination d-flex justify-content-center">
  <div class="mb-3 mr-3">
  <button class="prev-page btn btn-outline-secondary my-2 mb-3">Previous</button>
  </div>
  <div id="pages" class="mb-3 text-center pages">

  </div>
  <div class="mb-3 ml-3">
  <button class="mb-3 next-page btn btn-outline-secondary my-2">Next</button>
  </div>
  </div>
  `;

  const PageLink = (i) =>
  `<button class="btn btn-outline-secondary paging-link mx-1 my-2" data-page="`+i+`">`+i + `</button>`;


  displayWarnings();

  initInternalDataStructure();

  initSearch();
  initPaging();
  filter();


  function filter(){
    currentPage = 1;
    getData(currentFilter, currentPage);
    updateDOM();
    options.afterUpdate();
  }

  function getData(searchQuery = null){
    filteredData = [];
    currentFilter = searchQuery;
    if(searchQuery != null && searchQuery != ""){
      //Filtern
      $.each(datatableData, (i, r) => {
        if(options.isInFilter.call(this,searchQuery, r)){
          filteredData.push(r);
        }
      });
    }else{
      filteredData = datatableData;
    }
  }

  function pageData(page){
    return filteredData.slice((page - 1) * options.elementsPerPage, page * options.elementsPerPage);
  }

  function isInFilter(query, row){
    var isInFilter = false;
    var values = row.cellValues;
    $.each(values, (i, value) => {
      if(value.toUpperCase().indexOf(query.toUpperCase()) >= 0){
        isInFilter = true;
      }
    });
    return isInFilter;
  }


  function updateDOM(){
    clearMarkup();

    //First: Update Message
    if(filteredData.length > 1){
      setMessage("Found " + filteredData.length + " " + options.namePlural + ".");
    }else if(filteredData.length == 1){
      setMessage(filteredData.length + " " + options.nameSingular + " found.");
    }else{
      setMessage("Found no " + options.namePlural + ".");
    }

    //Then: Page the data

    var pagedData = pageData(currentPage);

    $.each(pagedData, (i, row) => {

      if(options.isTable){
        rootnode.find('tbody').find("[datatable-id='" + row.datatableId + "']").show();
      }else{
        rootnode.find("[datatable-id='" + row.datatableId + "']").show();
      }
    });

    if(currentFilter == "" || currentFilter == null){
      $(options.searchSelector).find('.search-reset').hide();
    }else{
      $(options.searchSelector).find('.search-reset').show();
    }

    updatePaging();
    options.afterUpdate();
  }

  function clearMarkup(){
    if(options.isTable){
      rootnode.find('tbody').find('tr').hide();
    }else{
      rootnode.find(options.elementSelector).hide();
    }
  }

  function initInternalDataStructure(){
    var elements = null;
    if(options.isTable){
      elements = rootnode.find('tbody').find('tr');
    }else{
      elements = rootnode.find(options.elementSelector);
    }


    elements.each((i,el) => {
      el = $(el);

      var dataCellValues = [];

      var cells = el.find('[data-cellvalue]');
      cells.each((i,cell) => {
        cell = $(cell);
        dataCellValues.push(cell.attr('data-cellvalue'));
      });

      // add an internal id to the element
      el.attr("datatable-id", i);


      var dataElement = {
        datatableId: i,
        cellValues: dataCellValues,
      }

      datatableData.push(dataElement);
    });
  }

  function initPaging(){
    var pagingContainer = $(options.pagingSelector);

    pagingContainer.empty().append(Paging);

    var prevPage = pagingContainer.find('.prev-page');
    var nextPage = pagingContainer.find('.next-page');
    var pages = pagingContainer.find('#pages');

    var maxPages = Math.ceil(datatableData.length / options.elementsPerPage);

    for (var i = 1; i<= maxPages; i++) {
      pages.append(PageLink(i));
    }
    pagingContainer.find('.paging-link[data-page="1"]').after('<span class="pages-skipped pages-skipped-before">...</span>');
    pagingContainer.find('.paging-link[data-page="'+maxPages+'"]').before('<span class="pages-skipped pages-skipped-after">...</span>');


    pagingContainer.find('.paging-link').on('click',(e) => {
      currentPage = parseInt($(e.target).attr('data-page'));
      updateDOM();
      options.afterUpdate();
    });
    nextPage.click(() => {
      currentPage += 1;
      if(currentPage > maxPages){
        currentPage = maxPages;
      }
      updateDOM();
      options.afterUpdate();
    });
    prevPage.on('click', function(){
      currentPage -= 1;
      if(currentPage < 1){
        currentPage = 1;
      }
      updateDOM();
      options.afterUpdate();
    });
  }

  function updatePaging(){
    var pagingContainer = $(options.pagingSelector);
    var maxPages = Math.ceil(filteredData.length / options.elementsPerPage);

    //Platzhalter vor letzte Page setzen
    pagingContainer.find('.pages-skipped-after').remove();
    pagingContainer.find('.paging-link[data-page="'+maxPages+'"]').before('<span class="pages-skipped pages-skipped-after">...</span>');


    if(currentPage == 1){
      pagingContainer.find('.prev-page').hide();
    }else{
      pagingContainer.find('.prev-page').show();
    }

    if(currentPage == maxPages || maxPages == 0){
      pagingContainer.find('.next-page').hide();
    }else{
      pagingContainer.find('.next-page').show();
    }

    // Alle Links > aktuelle Pages ausblenden
    pagingContainer.find('.paging-link').each((i, link) => {
      link = $(link);
      if(link.attr('data-page') > maxPages || maxPages == 1){
        link.hide();
      }else{
        link.show();
      }
    });

    //Links zw. currentPage und 1 bzw maxPages ausblenden
    // $('.paging-link').removeClass('hidden-before-current').removeClass('hidden-after-current');
    if(currentPage - 1 > 2){
      for(var i = 2; i < currentPage - 1; i++){
        pagingContainer.find('.paging-link[data-page="' + i + '"]').hide();
      }
      pagingContainer.find('.pages-skipped-before').show();
    }else{
      pagingContainer.find('.pages-skipped-before').hide();
    }
    if(currentPage + 2 < maxPages){
      for(i = currentPage + 2; i < maxPages; i++){
        pagingContainer.find('.paging-link[data-page="' + i + '"]').hide();
      }
      pagingContainer.find('.pages-skipped-after').show();
    }else{
      pagingContainer.find('.pages-skipped-after').hide();
    }

    pagingContainer.find('.paging-link').removeClass('current');
    pagingContainer.find('.paging-link[data-page="' + currentPage + '"]').addClass('current');

  }


  function initSearch(){

    $(options.searchSelector).empty().append(SearchForm);

    var searchForm = $(options.searchSelector).find('form');
    var searchInput = searchForm.find('.search-input');
    searchMessage = searchForm.find('.search-notification');

    searchForm.find('.search-reset').click((e) => {
      e.preventDefault();
      currentFilter = "";
      searchInput.val(currentFilter);
      currentPage = 1;
      filter();

    });

    searchForm.on('submit', function(e){
      e.preventDefault();
      currentFilter = searchInput.val();
      currentPage = 1;
      filter();
    });

    searchInput.on('input',() => {
      currentFilter = searchInput.val();
      currentPage = 1;
      filter();
    });
  }

  function setMessage(message){
    searchMessage.text(message);
  }

  function displayWarnings(){
    if(options.table != null){
      //Table als Grundelement
      if(!rootnode.is("table")){
        showError('error', 'datatable must be called on a table Element');
      }else{
        if(!rootnode.find('tbody') || !rootnode.find('thead')){
          showError('error', 'table needs to have a thead and tbody for datatable to work');
        }
      }
    }
    if(options.paging && options.pagingSelector == null){
      showError('error', 'if you want paging, you need to specify pagingSelectors');
    }
    if(options.search && options.searchSelector == null){
      showError('error', 'if you want search, you need to specify a searchSelector');
    }
    if(options.search && $.isFunction( options.isInFilter )){
      showError('info', 'datatable falling back to default isInFilter');
    }
  }

  //eslint-disable-next-line no-unused-vars
  function debug(object){
    // eslint-disable-next-line no-console
    console.log(object);
  }

  function showError(type, text){
    // eslint-disable-next-line no-console
    console && console[type] && console[type]('datatable: ' + text);
  }
}
